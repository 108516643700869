<template>
  <v-card class="leaderboard">
    <v-card-title>Leaderboard</v-card-title>
    <Loading
      v-if="profileLoading && !profileList.length"
      :loading="profileLoading"
      message="Loading Leaderboard"
    />
    <LeaderList
      v-else
      :entry-list="profileList"
    />
  </v-card>
</template>

<script>
import LeaderList from '@/components/LeaderList.vue'
import Loading from '@/components/Loading.vue'
import { mapState } from 'vuex'

export default {
  components: {
    LeaderList,
    Loading,
  },
  data: function() {
    return {
      polling: null,
    }
  },
  computed: {
    ...mapState({
      profileList: state => state.profiles.all,
      profileLoading: state => state.profiles.loading,
    }),
  },
  created: function () {
    this.updateStats()
    this.autoRefresh()
  },
  beforeDestroy: function() {
    clearInterval(this.polling)
  },
  methods: {
    updateStats() {
      this.$store.dispatch('profiles/getAll')
    },
    autoRefresh() {
      this.polling = setInterval(() => {
        this.updateStats()
      }, 1*60*1000)
    },
  },
}
</script>
