<template>
  <v-container
    v-if="loading"
  >
    <v-row
      class="fill-height"
      align-content="center"
      justify="center"
    >
      <v-col
        class="subtitle-1 text-center"
        cols="12"
      >
        {{ message }}
      </v-col>
      <v-col cols="6">
        <v-progress-linear
          indeterminate
          rounded
          height="6"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      required: false,
    },
    message: {
      type: String,
      required: false,
      default: "Herding Cats"
    },
  },
}
</script>
